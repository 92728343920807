@keyframes gradient {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.promptTextArea {
  border-style: none;
  background: transparent;
  outline: none;
  color: #3c4250;
  width: 100%;
}

.webflowStyleInput {
  border-style: none;
  background: transparent;
  outline: none;
  color: #3c4250;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  border-radius: 2px;
  padding: 1.4rem 2rem 1.6rem;
  background: rgba(0, 0, 0, 0.125);
  font-size: 14px;
}

.webflowStyleInput:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  z-index: 999;
  height: 2px;
  border-bottom-left-radius: 2px;
  border-bottom-right-radius: 2px;
  background-position: 0% 0%;
  background: linear-gradient(
    to right,
    #b294ff,
    #e54f6d,
    #feffb8,
    #e54f6d,
    #b294ff,
    #e54f6d
  );
  background-size: 500% auto;
  animation: gradient 3s linear infinite;
}

.webflowStyleInput input {
  flex-grow: 1;
  color: #3c4250;
  font-size: 1.8rem;
  line-height: 2.4rem;
  vertical-align: middle;
}

.webflowStyleInput -webkit-input-placeholder {
  color: #7881a1;
}

.webflowStyleInput button {
  color: #7881a1;
  font-size: 2.4rem;
  line-height: 2.4rem;
  vertical-align: middle;
  transition: color 0.25s;
}

.webflowStyleInput:hover {
  color: #3c4250;
}

button:hover {
  cursor: pointer;
}

.title {
  text-align: center;
  font-size: 40px;
  max-width: 500px;
  font-weight: 700;
  color: #3c4250;
  padding-bottom: 20px;
}

.excel {
  color: #ffc60b;
}

.social-container {
  padding-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.counter {
  color: #3c4250;
  font-size: 14px;
}

.iconButton {
  color: #3c4250;
  font-size: 20px;
  cursor: pointer;
}

.iconButton:hover {
  color: #ffc60b;
}

.languagePicker {
  max-width: 400px;
  width: 100%;
}

.questionTypePicker {
  height: 40px;
  background: none;
  border-radius: 4px;
  color: #5f5f5f;
  border-color: #c9a9a3;
}
