.webflowStyleInput {
  border-style: none;
  background: transparent;
  outline: none;
  display: flex;
  flex-direction: row;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  border-radius: 2px;
  padding: 1.4rem 2rem 1.6rem;
  background: rgba(57, 63, 84, 0.8);
  flex-grow: 1;
  color: #3c4250;
  position: relative;
}
