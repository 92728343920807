.buttonText {
  color: #000000;
  font-weight: 700;
  font-size: 10px;
  margin: 0;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.enabled {
  padding: 10px;
  background-color: #ffc60b;
  min-width: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: pointer;
}

.disabled {
  padding: 10px;
  background-color: rgb(194, 194, 194);
  min-width: 100px;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
  cursor: default;
}
