.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 1000px;
  padding: 20px;
  margin: 0 auto;
}

.internalContainer {
  background-color: rgba(57, 63, 84, 0.8);
  padding: 20px;
  border-radius: 10px;
}

.prompt {
  font-size: 22px;
  color: #3c4250;
}

.result {
  color: #3c4250;
  font-size: 18px;
}

.title {
  color: #3c4250;
  font-size: 22px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.retryContainer {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.resultText {
  font-size: 16px;
  color: #3c4250;
  margin: 2px;
}

.questionText {
  font-size: 16px;
  color: #3c4250;
  margin: 2px;
  font-weight: 600;
}

.iconButton {
  color: #3c4250;
  font-size: 20px;
  cursor: pointer;
}

.editingField {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0 auto;
  border-radius: 2px;
  padding: 1.4rem 2rem 1.6rem;
  background: white;
}

.optionsList {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin: 0;
}

.optionsListItem {
  margin: 0;
  padding: 0;
}
