.webflowStyleInputContainer {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  max-width: 400px;
  min-width: 300px;
  margin: 0 auto;
  border-radius: 2px;
  background: rgba(0, 0, 0, 0.125);
  flex-grow: 1;
  color: #3c4250;
  vertical-align: middle;
  height: 50px;
  justify-content: center;
  align-items: center;
}

.webflowStyleInput {
  outline: none;
  position: relative;
  border-style: none;
  background: transparent;
  outline: none;
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  flex-grow: 1;
  color: #3c4250;
  vertical-align: middle;
  padding: 1rem 1rem 1rem;
  height: 100%;
  font-size: 13px;
}

.logoContainer {
  position: absolute;
  right: 1rem;
}
