.wrapper {
  min-width: 100vw;
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  flex-wrap: wrap;

  background-repeat: repeat-y;
  background-size: 250%;
}
